import React, { useEffect, useState } from "react";
import styles from './Content.module.scss';
import * as _ from "lodash";
import { useToasts } from "react-toast-notifications";
import {
  Accesses,
  AccessListResponse,
  AccessPurposes,
} from "@common/typing";
import { AccessesService } from "@common/units-api";
import { authManager } from '@common/authentication';
import { Button, DraggableGrid, Icon, ThemeProvider } from "@vacasa/react-components-lib";
import _default from "@vacasa/react-components-lib/lib/themes/default";
import { AdminManagerService } from "../../services/admin-manager.service"
import { NotesComponent } from "./NotesComponent/NotesComponent";
import { Row } from "./Row";
import Spinner from 'react-spinner-material';
import { Modal } from "@material-ui/core";
import AccessDetailStyles from "../modal/AccessDetailsComponent.module.scss";

interface ContentProps {}

export const Content: React.FunctionComponent<ContentProps> = (_props) => {
  const [isLoading, setIsLoading] = useState(false);
  const unitService = new AccessesService(authManager.getJwt());
  const info = authManager.getInfoFromAdmin<{ user: string; unitId: number; }>();
  const adminManager = new AdminManagerService();
  const [accessesList, setAccessesList] = useState<{ string: object }>({});
  const [originalAccessesList, setOriginalAccessesList] = useState<{string: object}>({});
  const [purposes, setPurposes] = useState([]);
  const { addToast } = useToasts();
  const [isLoadingDragAndDrop, setIsLoadingDragAndDrop] = useState<{string: boolean}>({});
  const [accessTypeId, setAccessTypeId] = useState<number>(1); //we set it in 1 because now we only need L
  const [hideOrderButton, setHideOrderButton] = useState<{string: boolean}>({});
  const [handleFunctionsDragAndDrop, setHandleFunctionsDragAndDrop] = useState<object>({});
  const [reloadNotes, setReloadNotes] = useState<boolean>(true);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [pendingBackupAccess, setPendingBackupAccess] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [accessToDelete, setAccessToDelete] = useState<number>(null);
  const [unitAccessPurpose, setUnitAccessPurpose] = useState<string>("unit-access");

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        if (+adminManager.getSuccess()) {
          addToast("Saved Access", {appearance: "success"});
        }
        await loadUnitAccess();
        setIsReady(true)
      } catch (e) {
        addToast(`Error loading Unit Access information`, { appearance: "error" });
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    let height = document.getElementById('root')?.clientHeight;
    window.parent.postMessage({action: `${height}px`, type: 'resize'}, '*');
  } );

  useEffect(() => {
    if (null === isReady) {
      return;
    }
    loadHandleDragAndDrop();
  }, [isReady]);

  const loadUnitAccess = async () => {
    let sortedUnitAccess: Array<Accesses>;
    let sequenceByPurpose: object = {};
    let buttonByPurpose: object = {};
    let loadingByPurpose: object = {};
    let unitAccessList: object = {};

    try {
      const purposesResponse = await unitService.getPurposesByAccessType(accessTypeId);
      if (!_.isNil(purposesResponse)) {
        setPurposes(purposesResponse);
        setUnitAccessPurpose(purposesResponse.find(x => x.attributes.id === 1).attributes.purpose_name);
        for (const purpose of purposesResponse) {
          sequenceByPurpose = {...sequenceByPurpose, [purpose.attributes.purpose_name]: {}};
          unitAccessList = {...unitAccessList, [purpose.attributes.purpose_name]: []};
          buttonByPurpose = {...buttonByPurpose, [purpose.attributes.purpose_name]: true};
          loadingByPurpose = {...loadingByPurpose, [purpose.attributes.purpose_name]: false};
        }
        setHideOrderButton(buttonByPurpose);
        setIsLoadingDragAndDrop(loadingByPurpose);
      }
      const unitAccessTypeResponse: AccessListResponse = await unitService.getUnitAccesses(info.unitId);
      if (!_.isNil(unitAccessTypeResponse.data)) {
        sortedUnitAccess = unitAccessTypeResponse.data.sort((a, b) => {
          // Only sort on purpose_id if not identical
          if (a.attributes.purpose_id < b.attributes.purpose_id) return -1;
          if (a.attributes.purpose_id > b.attributes.purpose_id) return 1;
          // Sort on access_sequence.
          if (a.attributes.access_sequence < b.attributes.access_sequence) return -1;
          if (a.attributes.access_sequence > b.attributes.access_sequence) return 1;
          // Both identical, return 0
          return 0;
        });

        if (sortedUnitAccess.length > 0 ) {
          sortedUnitAccess.map((item) => {
            for (const purpose of purposesResponse) {
              if (+item.attributes.purpose_id === purpose.id) {
                unitAccessList[`${purpose?.attributes?.purpose_name }`].push(setRows(item));
                sequenceByPurpose[`${purpose?.attributes?.purpose_name }`] = {...sequenceByPurpose[`${purpose?.attributes?.purpose_name }`], [item.id]: item.attributes.access_sequence}
              }
            }
          });
          setAccessesList(unitAccessList);
          setOriginalAccessesList(unitAccessList);
        }

        const unitHasSmartHomeAccesses = unitAccessTypeResponse.data.find(a => a.attributes.smart_lock_id !== null);
        const unitHasBackupAccesses = unitAccessTypeResponse.data.find(a => a.attributes.purpose_id === 2);
        if (unitHasSmartHomeAccesses && !unitHasBackupAccesses) setPendingBackupAccess(true);
      }
    } catch (e) {
      addToast(`Error loading Access Type information`, { appearance: "error" });
      setIsLoading(false);
    }
  };

  const loadHandleDragAndDrop = () => {
    let handleFunctions: object = {};

    for (const purpose of purposes) {
      handleFunctions = {
        ...handleFunctions,
        [`${purpose.attributes.purpose_name}`]: (currentOrder: object) => handleChangeSequence(currentOrder, purpose.attributes.purpose_name)
      };
    }
    setHandleFunctionsDragAndDrop(handleFunctions);
  }

  const setRows = (access: Accesses) => {
    return {
      id: access.id,
      order: access.attributes.access_sequence,
      content: (<Row
        id={access.id}
        name={access.attributes.access_name}
        lockType={access?.attributes?.lock_type?.display_name}
        location={access.attributes.location}
        information={{validate: access.attributes.validated, notes: access.attributes.internal_notes === null ? false : true, smartHome: access.attributes.smart_lock_id === null ? false : true}}
        disableDeleteButton={access?.attributes.smart_lock_id === null ? false : true }
        openDeleteModal={handleDeleteModal}
        openEditForm={handleOpenForm}
      />)
    }
  }

  const handleOpenCreationModal = () => {
    window.parent.postMessage({action: 'click', type: 'showAccessModal'}, '*');
  };

  const handleDeleteModal = (id: number) => {
    setModalVisible(true)
    setAccessToDelete(id);
  };

  const handleOpenForm = (id: number) => {
    window.parent.postMessage({action: 'click', type: 'showAccessModal', id: id}, '*');
  };

  const deleteAccess = async () => {
    try {
      await unitService.deleteUnitAccess(accessToDelete, info.user);
      setModalVisible(false);
      await loadUnitAccess();
      addToast("Access deleted succefully", { appearance: "success" });
    } catch (e) {
      addToast(`Error deleting access ${e}`, { appearance: "error" });
      setModalVisible(false);
    } finally {
      setAccessToDelete(null);
    }
  }

  const renderModal = (): JSX.Element => {
    return (<Modal
      open={modalVisible}
      onClose={setModalVisible}
    >
      <ThemeProvider theme={_default}>
        <div className={`${AccessDetailStyles.paper} ${AccessDetailStyles.modalStyle}`}>
          <h2 id="simple-modal-title">Delete</h2>
          <p id="simple-modal-description">
            Are you sure you want to delete this access?
          </p>
          <div className="row">
            <div className={`col-md-3 offset-md-2 ${styles.modalButton}`}>
              <Button variant="secondary" onClick={() => deleteAccess()}>
                Confirm
              </Button>
            </div>
            <div className={`col-md-3 offset-md-1 ${styles.modalButton}`}>
              <Button variant="info" onClick={() => setModalVisible(false)}>
                Close
              </Button>
            </div>
          </div>
        </div>
      </ThemeProvider>
    </Modal >);
  }

  const handleChangeSequence = (currentOrder: object, purpose: string,) => {
    if (hideOrderButton[purpose]) {
      handleBlockDiv(purpose);
      setHideOrderButton({ ...hideOrderButton, [purpose]: false });
    }

    const clone = _.cloneDeep(accessesList[purpose]);
    for (const access of clone) {
      const index = Object.keys(currentOrder).find(x => +x === +access.id);
      const newOrder = currentOrder[index];
      access.order = newOrder;
    }
    setAccessesList({...accessesList, [purpose]: clone});
  }

  const saveSequence = async (purpose) => {
    setIsLoadingDragAndDrop({ ...isLoadingDragAndDrop, [purpose]: true });
    if (purpose === unitAccessPurpose) setReloadNotes(false);
    let request = [] as Array<Accesses>;
    for (const index in accessesList[purpose]) {
      request.push({
        type: "unit_access_sequence",
        id: +accessesList[purpose][index].id,
        attributes: {
          id: +accessesList[purpose][index].id,
          access_sequence: accessesList[purpose][index].order + 1,
          modified_by: info.user
        }
      });
    }
    try {
      request.sort((a, b) => a.attributes.access_sequence - b.attributes.access_sequence);
      await unitService.updateUnitAccessesSequence(info.unitId, request);
      addToast("Order updated Successfully", {appearance: "success"});
      setHideOrderButton({ ...hideOrderButton, [purpose]: true });
      setOriginalAccessesList(accessesList);
      removeBlockDivs(purpose);
      if (purpose === unitAccessPurpose) setReloadNotes(true);
    } catch (e) {
      console.error(e);
      const messageDetail = e.response?.data[0]?.detail?.error ?? e;
      addToast(`Error updating order ${messageDetail}`, { appearance: "error" });
    } finally {
      setIsLoadingDragAndDrop({ ...isLoadingDragAndDrop, [purpose]: false });
    }
  }

  // Función para bloquear el div con la capa de oscuridad
  const handleBlockDiv = (purpose: string) => {
    for (const p of purposes) {
      if (p?.attributes?.purpose_name !== purpose) {
        const purposeDiv = document.getElementById(`purposeAccesses-${p?.attributes?.purpose_name}`);
        const blockDiv = document.getElementById(`blockDiv-${p?.attributes?.purpose_name}`);

        const rect = purposeDiv.getBoundingClientRect();

        blockDiv.style.top = rect.top + 'px';
        blockDiv.style.left = rect.left + 'px';
        blockDiv.style.width = rect.width + 'px';
        blockDiv.style.height = rect.height + 'px';
        blockDiv.style.display = 'flex'; // Mostrar la capa de bloqueo
      }
    }
  }

  // Cancel order changes
  const handleCancelButton = (purpose: string) => {
    // Show loading and hide buttons
    setIsLoadingDragAndDrop({ ...isLoadingDragAndDrop, [purpose]: true });
    setHideOrderButton({ ...hideOrderButton, [purpose]: true });

    // Remove block div for other purposes
    removeBlockDivs(purpose);

    setTimeout(() => {
      // Sort accesses
      const originalItems = _.cloneDeep(originalAccessesList[purpose]);
      const sortedItems = originalItems.sort((a, b) => a.order - b.order);

      setAccessesList(prevState => ({
        ...prevState,
        [purpose]: sortedItems
      }));

      // 6. Hide loading
      setTimeout(() => {
        setIsLoadingDragAndDrop(prevState => ({
          ...prevState,
          [purpose]: false
        }));
      }, 300);
    }, 0);
  };

  const removeBlockDivs = (purpose: string) => {
    for (const p of purposes) {
      if (p?.attributes?.purpose_name !== purpose) {
        const blockDiv = document.getElementById(`blockDiv-${p?.attributes?.purpose_name}`);
        if (blockDiv) {
          blockDiv.style.display = 'none';
        }
      }
    }
  }

  return (
    <div className={`${styles.summaryApp}`}>
      <div className={`container-fluid ${styles.Container}`}>
        <div className={styles.optionsContainer}>
          <b><a href={"#"} onClick={() => handleOpenCreationModal()} className={styles.optionLink}>+ Add Access</a></b>
        </div>
        {isLoading ? (
          <div className="row">
            <div className={`col ${styles.centerContent}`}>
              <h6>Loading data...</h6>
            </div>
          </div>
        ) : (
          <React.Fragment>
            <div className={`${styles.ContainerAccessList}`}>
              {purposes.map((purpose: AccessPurposes, index) => {
                return (
                  <React.Fragment>
                    <div id={"purpose-name"} className={styles.purposeHeader}>
                      <span><b>{purpose?.attributes?.display_name.toUpperCase()}</b></span>
                    </div>
                    <ThemeProvider theme={_default}>
                      <div id={`purposeAccesses-${purpose?.attributes?.purpose_name}`} className={`${styles.containerTable} ${purpose?.attributes.id === 2 && pendingBackupAccess ? styles.containerTable_backupPending : ""}`}>
                        {accessesList.hasOwnProperty(purpose?.attributes?.purpose_name) && accessesList[`${purpose?.attributes?.purpose_name }`].length > 0 ? (
                          <React.Fragment>
                            <div className={`row ${styles.tableHeader}`}>
                              <div className={"col-sm-1"}></div>
                              <div className={"col-sm-2"}>
                                <span><b>Name</b></span>
                              </div>
                              <div className={"col-sm-2"}>
                                <span><b>Lock type</b></span>
                              </div>
                              <div className={"col-sm-3"}>
                                <span><b>Location</b></span>
                              </div>
                              <div className={"col-sm-3"}></div>
                              <div className={"col-sm-1"}>
                                <span><b>Action</b></span>
                              </div>
                            </div>
                            {!isLoadingDragAndDrop[`${purpose?.attributes?.purpose_name }`] ? (
                              <DraggableGrid
                                items={accessesList[`${purpose?.attributes?.purpose_name }`]}
                                customClass={styles.customGrid}
                                onOrderChanged={handleFunctionsDragAndDrop[`${purpose?.attributes?.purpose_name }`]}
                              />
                            ) : (
                              <div>
                                <Spinner radius={50} color={"#333"} stroke={2} visible={true} />
                              </div>
                            )}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {purpose?.attributes.id === 2 && pendingBackupAccess ? (
                              <div className={styles.backupMessage}>
                                <Icon.AlertTriangle className="pointer" name="info" width={25} height={25}/>
                                <span><b>This unit has a E-lock but no backup is configured. Backup access is required.</b></span>
                              </div>
                            ) : (
                              <div className={styles.emptyMessage}>
                                <Icon.AlertCircle className="pointer" name="info" width={25} height={25}/>
                                <span><b>No access configured</b></span>
                              </div>
                            )}
                          </React.Fragment>
                        )}
                        <div className={styles.buttonsDiv}>
                          <div className={styles.left}>
                            {!hideOrderButton[`${purpose?.attributes?.purpose_name }`] ? (
                              <div>
                                <Button onClick={() => saveSequence(purpose?.attributes?.purpose_name)} variant={"info"} size={"small"} customClass={styles.orderButton}>
                                  <b>Save Order<Icon.Repeat/></b>
                                </Button>
                                <Button onClick={() => {handleCancelButton(purpose?.attributes.purpose_name)}} variant={"danger"} size={"small"} customClass={styles.cancelButton}>
                                  <b>Cancel</b>
                                </Button>
                              </div>
                            ) : (
                              <React.Fragment/>
                            )}
                          </div>
                          <div className={styles.right}>
                            <Button onClick={() => saveSequence(purpose?.attributes?.purpose_name)} variant={"info"} size={"small"} customClass={`${styles.addButton} ${styles.hide}`}>
                              <b>Add <Icon.Plus/></b>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </ThemeProvider>
                    <div className={`${styles.block}`} id={`blockDiv-${purpose?.attributes?.purpose_name}`}>
                      <div className={styles.message}>
                        <p>You must Save Order or Cancel before continue</p>
                      </div>
                    </div>
                  </React.Fragment>
                )
              })}

              {reloadNotes ? (
                <React.Fragment>
                  <div className={styles.purposeHeader}>
                    <span><b>INSTRUCTIONS PREVIEW</b></span>
                  </div>
                  <div className={`${styles.containerTable} ${styles.containerTable_notes}`}>
                    <NotesComponent/>
                  </div>
                </React.Fragment>
              ) : (
                <div className="row">
                  <div className={`col ${styles.centerContent}`}>
                    <h6>Loading data...</h6>
                  </div>
                </div>
              )}
            </div>
          </React.Fragment>
        )}

      </div>
      {renderModal()}
    </div>
  );
};