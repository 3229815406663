import React from "react";
import styles from './Row.module.scss';
import { Badge, Icon, Tooltip } from "@vacasa/react-components-lib";

interface RowProps {
  id: number;
  name: string;
  lockType: string;
  location: string;
  information: {
    validate: boolean;
    notes: boolean;
    smartHome: boolean;
  }
  disableDeleteButton: boolean;
  openDeleteModal: Function;
  openEditForm: Function;
}

export const Row: React.FunctionComponent<RowProps> = (props) => {
  const {id, name, lockType, location, information, disableDeleteButton, openDeleteModal, openEditForm} = props;

  return (
    <React.Fragment>
      <div className={`row ${styles.dragAndDropRow} ${!information.validate ? styles.dragAndDropRow_notValidated : ""}`}>
        <div className={"col-sm-1"}>
          <Icon.Menu width={20} height={20} className={styles.icon}/>
        </div>
        <div className={"col-sm-2"}>
          <span>{name}</span>
        </div>
        <div className={"col-sm-2"}>
          <span>{lockType}</span>
        </div>
        <div className={"col-sm-3"}>
          <span>{location}</span>
        </div>
        <div className={`col-sm-3 ${styles.pillsCol}`}>
          {!information.validate ? (
            <Badge
              disabled={false}
              closeButton={false}
              isPill={true}
              text="MISSING INFORMATION"
              outline={false}
              variant="error"
              customClass={`${styles.customPill}`}
            />
          ) : (<React.Fragment/>)}
          {information.notes ? (
            <Badge
              disabled={false}
              closeButton={false}
              isPill={true}
              text="INTERNAL NOTE"
              outline={false}
              variant="default"
              customClass={`${styles.customPill} ${styles.notesPill}`}
            />
          ) : (<React.Fragment/>)}
          {information.smartHome ? (
            <Badge
            disabled={false}
            closeButton={false}
            isPill={true}
            text="SMART HOME"
            outline={false}
            variant="default"
            customClass={`${styles.customPill} ${styles.smartHomePill}`}
          />
          ) : (<React.Fragment/>)}
        </div>
        <div className={`col-sm-1 ${styles.actionCol}`}>
          <Tooltip message={disableDeleteButton ? "Cannot delete accesses from Smart Home" : "Remove"} placement={"bottom"}>
            <button type="button" className={`${styles.notButton}`} onClick={() => openDeleteModal(id)} disabled={disableDeleteButton}>
              <Icon.Trash2 className={`${disableDeleteButton ? styles.remove_disable : styles.remove}`} name="remove" width={18} height={18} />
            </button>
          </Tooltip>
          <Tooltip message={"Edit"} placement={"bottom"}>
            <button type="button" className={`${styles.notButton} ${styles.editButton}`} onClick={() => openEditForm(id)}>
              <Icon.Edit className={styles.edit} name="edit" width={18} height={18} />
            </button>
          </Tooltip>
        </div>
      </div>
    </React.Fragment>
  );
}